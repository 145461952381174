.thankYou {
    color: #d0d79c;
}

.contactForm {
    margin-top: 3.5rem;
}

.contactHeading {
    color: #d0d79c;
}

.danger {
    background-color: #FF5656;
    border: solid;
    border-color: black;
    border-radius: .3rem;
    width: 25%;
    margin-right: auto;
    margin-left: auto;
}

.input {
    color: #d0d79c;
    text-decoration: underline;
}

#formName {
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

#formEmail {
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

#formMessage {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}